import "./App.css";
import Header from "./component/Header";
import Home from "./component/home";
import Footer from "./component/Footer";
import ReactGA from "react-ga";
import Appconfig from "./Appconfig";
import { useEffect } from "react";

ReactGA.initialize(Appconfig.GOOGLE.GA_TRACKING_CODE);

function App() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  });
  return (
    <div className="App">
      <Header />
      <Home />
      <Footer />
    </div>
  );
}

export default App;
