import React from "react";
import "./style.css";

function index() {
  return (
    <div className="service_description" id="services">
      <div className="service_description_first">
        <span>
          <img src="./banner/service_description.jpg" alt="logo" />
        </span>
      </div>
      <div className="service_description_second">
        <span>See our Wide Range of Services</span>
        <span className="about_paragraph">
          We have worked with various businesses, organizations, and individuals
          and provided various services per our client's needs.
        </span>
      </div>
    </div>
  );
}

export default index;
