import React, { Component } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import "./style.css";
import { AiTwotoneStar } from "react-icons/ai";

export default class Testimonials extends Component {
  render() {
    return (
      <div className="testimonial_carousel">
        <h1>Testimonial</h1>

        <Carousel
          showArrows={true}
          infiniteLoop={true}
          showThumbs={false}
          showStatus={false}
          autoPlay={true}
          interval={6100}
        >
          <div>
            <div className="tetimonial_data">
              <h2>
                What our <br />
                Customer Says
              </h2>
              <div className="rating_division">
                <div className="stars">
                  <AiTwotoneStar />
                  <AiTwotoneStar />
                  <AiTwotoneStar />
                  <AiTwotoneStar />
                  <AiTwotoneStar />
                </div>
                <h2>Avarage Customer Rating</h2>
              </div>
            </div>
            <img
              className="testimonial_image"
              src="./client/bimarsh.jpg"
              alt="logo"
            />
            <div className="myCarousel">
              <p>
                Good for quick design and quick solutions. Online payment and
                job offer accepted.
              </p>
              <h3>(Bimarsh Panta)</h3>
            </div>
          </div>

          <div>
            <div className="tetimonial_data">
              <h2>
                What our <br />
                Customer Says
              </h2>
              <div>
                <div className="stars">
                  <AiTwotoneStar />
                  <AiTwotoneStar />
                  <AiTwotoneStar />
                  <AiTwotoneStar />
                  <AiTwotoneStar />
                </div>
                <h2>Avarage Customer Rating</h2>
              </div>
            </div>
            <img
              className="testimonial_image"
              src="./client/roshan.jpg"
              alt="logo"
            />
            <div className="myCarousel">
              <p>The best place for designing and printing.</p>
              <h3>(Roshan Raghubansi)</h3>
            </div>
          </div>
          <div>
            <div className="tetimonial_data">
              <h2>
                What our <br />
                Customer Says
              </h2>
              <div>
                <div className="stars">
                  <AiTwotoneStar />
                  <AiTwotoneStar />
                  <AiTwotoneStar />
                  <AiTwotoneStar />
                  <AiTwotoneStar />
                </div>
                <h2>Avarage Customer Rating</h2>
              </div>
            </div>
            <img
              className="testimonial_image"
              src="./client/tuddrom Birds.png"
              alt="logo"
            />
            <div className="myCarousel">
              <p>
              Working with Pannalal dai is always easy and comfortable; 
              he always delivers his work as per our deadline, and we can
             easily recommend it to others
              </p>
              <h3>(Tuddrom Nepal)</h3>
            </div>
          </div>
        </Carousel>
      </div>
    );
  }
}
