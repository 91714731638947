import React from "react";
import "./style.css";

function index() {
  return (
    <div className="main_header_container">
      <nav>
        <input type="checkbox" id="nav-toggle" />
        <div class="header_inner_logo">
          <a className="header_logo_link" href="#home_banner">
            <img
              class="header_logo"
              src="./logo/national_logo.png"
              alt="Logo"
            />
          </a>
        </div>
        <ul class="header_links">
          <li className="header_list">
            <a className="header_list_links" href="#home_banner">
              Home
            </a>
          </li>
          <li className="header_list">
            <a className="header_list_links" href="#about">
              About Us
            </a>
          </li>
          <li className="header_list">
            <a className="header_list_links" href="#services">
              Services
            </a>
          </li>

          <li className="header_list">
            <a className="header_list_links" href="#contact">
              Contact Us
            </a>
          </li>
        </ul>
        <label for="nav-toggle" class="icon-burger">
          <div class="line"></div>
          <div class="line"></div>
          <div class="line"></div>
        </label>
      </nav>
      <label for="nav-toggle" class="icon-burger">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
      </label>
    </div>
  );
}

export default index;
