import React from "react";
import "./style.css";

function index() {
  return (
    <section class="why_us whyus_section_bg" id="whyUs">
      <div class="whyus_container">
        <header class="whyus_section_header">
          <h3>Why Us?</h3>
        </header>
        <div className="whyus_dashboard_cards">
          <div className="whyus_dashboard_cards_single">
            <div>
              <span>
                <img src="./whyus/fifteen.png" alt="service_img" />
              </span>
            </div>
            <div className="whyus_cards_header">
              <h1>15+ years of experience</h1>
            </div>
          </div>
          <div className="whyus_dashboard_cards_single">
            <div>
              <span>
                <img src="./whyus/shipped.png" alt="service_img" />
              </span>
            </div>
            <div className="whyus_cards_header">
              <h1>On-time Delivery</h1>
            </div>
          </div>
          <div className="whyus_dashboard_cards_single">
            <div>
              <span>
                <img src="./whyus/work.png" alt="service_img" />
              </span>
            </div>
            <div className="whyus_cards_header">
              <h1>Quality Work</h1>
            </div>
          </div>
          <div className="whyus_dashboard_cards_single">
            <div>
              <span>
                <img src="./whyus/support.png" alt="service_img" />
              </span>
            </div>
            <div className="whyus_cards_header">
              <h1>Better Customer Service</h1>
            </div>
          </div>
          <div className="whyus_dashboard_cards_single">
            <div>
              <span>
                <img src="./whyus/best-price.png" alt="service_img" />
              </span>
            </div>
            <div className="whyus_cards_header">
              <h1>Best Price</h1>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default index;
