import React, { useState } from "react";
import "./style.css";
import { useForm } from "react-hook-form";
import { async } from "@firebase/util";

function Contact() {
  const [userData, setUserData] = useState({
    fullName: "",
    address: "",
    number: "",
    company: "",
    service: "",
    deadlines: "",
  });

  let name, value;
  const postUserData = (event) => {
    name = event.target.name;
    value = event.target.value;

    setUserData({ ...userData, [name]: value });
  };

  const submitData = async (event) => {
    event.preventDefault();
    const { fullName, address, number, company, service, deadlines } = userData;
    if (fullName && address && number && company && service && deadlines) {
      const res = fetch(
        "https://nationaldesignhouse-d5c66-default-rtdb.firebaseio.com//userDataRecords.json",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            fullName,
            address,
            number,
            company,
            service,
            deadlines,
          }),
        }
      );
      if (res) {
        alert("Data Stored.");
      } else {
        alert("Please Fill Form.");
      }
    } else {
      alert("Please Fill Form.");
    }
  };

  const {
    formState: { errors },
  } = useForm();

  // const onSubmit = (data) => {
  //   console.log(data);
  // };
  return (
    <div className="home_contat" id="contact">
      <div className="contact_speak">
        <h1>Let's Talk About Your Requirements.</h1>
        <span className="contact_paragraph">
        Please feel free to fill up this form, or you can contact us to discuss your specific needs.<br/>
        Address: Bajra Bir Marga, Bagbazar-28, Kathmandu, Nepal <br/>
        Contact number: 985-1341861 <br/>
        Whatsapp: +977 9803632154 <br/>
        Viber: 9803632154 <br/>
        </span>
      <h4>Locate us on Google Maps.</h4>
      <span>
      <iframe 
      src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14129.760371567123!2d85.3189961!3d27.7036951!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x8aab1d5575dac626!2sNational%20Design%20House!5e0!3m2!1sen!2snp!4v1674704277805!5m2!1sen!2snp" 
      width="300" 
      height="200" 
      style={{border:"0"}} 
      allowfullscreen="" 
      loading="lazy" 
      referrerpolicy="no-referrer-when-downgrade">
      </iframe>
      
      </span>

      </div>
      <form
        method="POST"
        // onSubmit={handleSubmit(onSubmit)}
        className="contact_fillform"
      >
        <div>
          <span>Name:</span>
          <input
            className="talkform_input"
            type="text"
            name="fullName"
            placeholder="Your full name"
            value={userData.fullName}
            onChange={postUserData}
          />
          {errors.name && errors.name.type === "required" && (
            <p>Name is required.</p>
          )}
        </div>
        <div>
          <span>Address:</span>
          <input
            className="talkform_input"
            type="text"
            name="address"
            placeholder="Your address"
            value={userData.address}
            onChange={postUserData}
          />
        </div>
        <div>
          <span>Phone Number:</span>
          <input
            className="talkform_input"
            type="number"
            name="number"
            placeholder="Your Phone number"
            value={userData.number}
            onChange={postUserData}
          />
          {errors.number && errors.number.type === "required" && (
            <p>Phone Number is Required.</p>
          )}
        </div>
        <div>
          <span>Company Name:</span>
          <input
            className="talkform_input"
            type="text"
            name="company"
            placeholder="Your address"
            value={userData.company}
            onChange={postUserData}
          />
        </div>

        <div>
          <span>What do you want(Print or design):</span>
          <select
            className="talkform_input"
            name="service"
            id="service_want"
            value={userData.service}
            onChange={postUserData}
            placeholder="please choose.."
          >
            <option value="Print">Print</option>
            <option value="Design">Design</option>
          </select>
        </div>
        <div>
          <span>Deadlines:</span>
          <input
            className="talkform_input"
            type="date"
            name="deadlines"
            placeholder="Deadlines"
            value={userData.deadlines}
            onChange={postUserData}
          />
          {errors.deadlines && <p>Please fill required deadline box.</p>}
        </div>
        <div>
          <button
            type="submit"
            className="talkform_submit"
            onClick={submitData}
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
}

export default Contact;
