import React from "react";
import "./style.css";

function index() {
  return (
    <section class="services section-bg" id="home_services">
      <div class="container">
        <header class="homeservice_section_header">
          <h3>Our Services.</h3>
        </header>
        <div className="dashboard_cards">
          <div className="dashboard_cards_single">
            <div className="image_div">
              <img
                clasName="service_images"
                src="./service/service1.png"
                alt="service_img"
              />
            </div>
            <div className="header_service">
              <h1>Collaboration Service to Universites</h1>
            </div>
          </div>
          <div className="dashboard_cards_single">
            <div className="image_div">
              <img
                clasName="service_images"
                src="./service/Bill.png"
                alt="service_img"
              />
            </div>
            <div className="header_service">
              <h1>Bill Print</h1>
            </div>
          </div>
          <div className="dashboard_cards_single">
            <div className="image_div">
              <img
                clasName="service_images"
                src="./service/ID Cards.png"
                alt="service_img"
              />
            </div>
            <div className="header_service">
              <h1>Id Card and lanyard print</h1>
            </div>
          </div>
          <div className="dashboard_cards_single">
            <div className="image_div">
              <img
                clasName="service_images"
                src="./service/Visting Card.png"
                alt="service_img"
              />
            </div>
            <div className="header_service">
              <h1>Visiting card</h1>
            </div>
          </div>
          <div className="dashboard_cards_single">
            <div className="image_div">
              <img
                clasName="service_images"
                src="./service/Brochure.png"
                alt="service_img"
              />
            </div>
            <div className="header_service">
              <h1>Brochure print</h1>
            </div>
          </div>
          <div className="dashboard_cards_single">
            <div className="image_div">
              <img
                clasName="service_images"
                src="./service/Fiber Bag Pint.png"
                alt="service_img"
              />
            </div>
            <div className="header_service">
              <h1>Fiber Bag Print</h1>
            </div>
          </div>
          <div className="dashboard_cards_single">
            <div className="image_div">
              <img
                clasName="service_images"
                src="./service/Cheque.png"
                alt="service_img"
              />
            </div>
            <div className="header_service">
              <h1>Cheque book print</h1>
            </div>
          </div>
          <div className="dashboard_cards_single">
            <div className="image_div">
              <img
                clasName="service_images"
                src="./service/letterhead.jpeg"
                alt="service_img"
              />
            </div>
            <div className="header_service">
              <h1>Letter head print</h1>
            </div>
          </div>
          <div className="dashboard_cards_single">
            <div className="image_div">
              <img
                clasName="service_images"
                src="./service/Stamps.png"
                alt="service_img"
              />
            </div>
            <div className="header_service">
              <h1>stamp</h1>
            </div>
          </div>
          <div className="dashboard_cards_single">
            <div className="image_div">
              <img
                clasName="service_images"
                src="./service/Harpic.png"
                alt="service_img"
              />
            </div>
            <div className="header_service">
              <h1>Product Label Print</h1>
            </div>
          </div>
          <div className="dashboard_cards_single">
            <div className="image_div">
              <img
                clasName="service_images"
                src="./service/T-Shirt Print.png"
                alt="service_img"
              />
            </div>
            <div className="header_service">
              <h1>Tshirt Print</h1>
            </div>
          </div>
          <div className="dashboard_cards_single">
            <div className="image_div">
              <img
                clasName="service_images"
                src="./service/Invitation.jpeg"
                alt="service_img"
              />
            </div>
            <div className="header_service">
              <h1>Invitaion Card</h1>
            </div>
          </div>
          <div className="dashboard_cards_single">
            <div className="image_div">
              <img
                clasName="service_images"
                src="./service/Calendar.png"
                alt="service_img"
              />
            </div>
            <div className="header_service">
              <h1>Calender</h1>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default index;
