import React from "react";
import About from "./Aboutus";
import Whyus from "./Why us";
import Servicedescription from "./Serviceadescrption";
import Contact from "./Contact";
import Service from "./Service";
import Testimonials from "./Testimonial/testimonial";
import Homecarousel from "./Homecarousel/homebanner";
import Client from "./Client";
import Clientheading from "./clientheading";

function home() {
  return (
    <div>
      <Homecarousel />

      <About />
      <Whyus />
      <Servicedescription />
      <Service />
      <Clientheading />
      <Client />
      <Testimonials />
      <Contact />
    </div>
  );
}

export default home;
