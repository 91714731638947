import React from "react";
import "./style.css";
import {
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaMapMarkerAlt,
  FaPhoneAlt,
  FaWhatsapp,
  FaViber,
  FaTelegram,
} from "react-icons/fa";
import { MdOutlineEmail } from "react-icons/md";

function index() {
  return (
    <div className="footer_container">
      <div className="footer_details">
        <div className="footer_details_division">
          <span className="footer_find">
            Find Us: <br />
            <span>
              Bagbazar-28, Kathmandu, Nepal
              <br />
              (Beside Shaheed Shukra School Gate)
            </span>
          </span>

          <div className="footer_icons">
            <span>
              <a href="https://www.facebook.com/design.house36">
                <img src="./social/Facebook.png" alt="logo" />
              </a>
            </span>
            <span>
              <a href="https://www.instagram.com/design.house36/">
                <img src="./social/Instagram.png" alt="logo" />
              </a>
            </span>
            <span>
              <a href="https://www.linkedin.com/company/nationaldesign-house">
                <img src="./social/LinkedIn.png" alt="logo" />
              </a>
            </span>
            <span>
              <a href="mailto: national.designhouse@gmail.com">
                <img src="./social/Gmail.png" alt="logo" />
              </a>
            </span>
            <span>
              <a href="https://goo.gl/maps/TqmQmXdXun4fV8ud9">
                <img src="./social/Google.png" alt="logo" />
              </a>
            </span>
          </div>
        </div>
        <div className="footer_details_second_division">
          <span>
            <img
              className="footer_logo"
              src="./logo/national_logo.png"
              alt="logo"
            />
          </span>
          <span>
            With over 15 years of experience, you can trust our design, print,
            and hard work to do a better-than-satisfactory job on all your
            printing needs.
          </span>
        </div>
        <div className="footer_details_third_division">
          <a className="footer_link" href="tel:+977 9851341861">
            <FaPhoneAlt />
            &nbsp; 9851341861
          </a>
          <a className="footer_link" href="tel:+977 9803632154">
            <FaPhoneAlt />
            &nbsp; 9803632154
          </a>
          <div className="footer_social">
            <span>
              <a href="https://wa.me/9779803632154">
                <FaWhatsapp />
              </a>
            </span>
            <span>
              <a href="viber://chat?number=+9779803632154">
                <FaViber />
              </a>
            </span>
            <span>
              <a href="https://t.me/national_design_house">
                <FaTelegram />
              </a>
            </span>
          </div>
        </div>
      </div>
      <div className="footer_designed">
        <span>
          CREATED WITH PASSION BY{" "}
          <a href="http://rodhi.digital/" target="_blank">DIGITAL RODHI</a> | COPYRIGHT &copy;
          NATIONALDESIGNHOUSE2022
        </span>
      </div>
    </div>
  );
}

export default index;
