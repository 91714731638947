import React from "react";
import "./style.css";

function index() {
  return (
    <div>
      <h3 className="homeproduct_heading">Our Clients</h3>
    </div>
  );
}

export default index;
