import React from "react";
import "./style.css";

function index() {
  return (
    <div className="about_us" id="about">
      <div className="aboutus_first">
        <span>A Few words about the National Design House</span>
        <span className="about_paragraph">
          It's our pleasure to inform you that we are one of the best Printing
          Press in Nepal. We give services in all sectors such as schools,
          colleges, hospitals, co-operatives, finances, banks, departmental
          stores, Overseas, Human Resources, foreign manpower agencies and many
          more...
          <br />
          Graphic Design, Screen Print, Leaflet, Pamphlet, Prospectus, Book
          Setting, Company Profile (Brochure), Cap, T-shirt, Calendar, Visiting
          Card, Letterhead, Record File, Cash Receipt, Payment Voucher, Bill
          Pad, Invoice, Stickers, Envelope, ID-Card and many more...... <br />
          <strong>All kinds of Design and printing Services.</strong>
        </span>
        {/* <span className="aboutus_link">
          <a href="/about" className="readmore">
            About us
          </a>
        </span> */}
      </div>
      <div className="aboutus_second">
        <span>
          <img src="./banner/aboutus.png" alt="logo" />
        </span>
      </div>
    </div>
  );
}

export default index;
