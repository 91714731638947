import React from "react";
import "./style.css";

function index() {
  return (
    <div className="home_slider_container">
      <div className="home_slider">
        <div className="home_slide_track">
          <div className="home_slide">
            <img
              className="home_image_slide"
              src="./client/client1.png"
              alt="client"
            />
          </div>
          <div className="home_slide">
            <img
              className="home_image_slide"
              src="./client/client2.png"
              alt="client"
            />
          </div>
          <div className="home_slide">
            <img
              className="home_image_slide"
              src="./client/client3.png"
              alt="client"
            />
          </div>
          <div className="home_slide">
            <img
              className="home_image_slide"
              src="./client/client4.png"
              alt="client"
            />
          </div>
          <div className="home_slide">
            <img
              className="home_image_slide"
              src="./client/client5.png"
              alt="client"
            />
          </div>
          <div className="home_slide">
            <img
              className="home_image_slide"
              src="./client/client6.png"
              alt="client"
            />
          </div>
          <div className="home_slide">
            <img
              className="home_image_slide"
              src="./client/client7.png"
              alt="client"
            />
          </div>
          <div className="home_slide">
            <img
              className="home_image_slide"
              src="./client/client12.png"
              alt="client"
            />
          </div>
          <div className="home_slide">
            <img
              className="home_image_slide"
              src="./client/client13.png"
              alt="client"
            />
          </div>
          <div className="home_slide">
            <img
              className="home_image_slide"
              src="./client/client14.png"
              alt="client"
            />
          </div>
          <div className="home_slide">
            <img
              className="home_image_slide"
              src="./client/client8.png"
              alt="client"
            />
          </div>
          <div className="home_slide">
            <img
              className="home_image_slide"
              src="./client/client9.png"
              alt="client"
            />
          </div>
          <div className="home_slide">
            <img
              className="home_image_slide"
              src="./client/client10.png"
              alt="client"
            />
          </div>
          <div className="home_slide">
            <img
              className="home_image_slide"
              src="./client/client11.png"
              alt="client"
            />
          </div>

          <div className="home_slide">
            <img
              className="home_image_slide"
              src="./client/client15.png"
              alt="client"
            />
          </div>
          <div className="home_slide">
            <img
              className="home_image_slide"
              src="./client/client16.png"
              alt="client"
            />
          </div>
          <div className="home_slide">
            <img
              className="home_image_slide"
              src="./client/client17.png"
              alt="client"
            />
          </div>
          <div className="home_slide">
            <img
              className="home_image_slide"
              src="./client/client18.png"
              alt="client"
            />
          </div>
          <div className="home_slide">
            <img
              className="home_image_slide"
              src="./client/client19.png"
              alt="client"
            />
          </div>
          <div className="home_slide">
            <img
              className="home_image_slide"
              src="./client/client20.png"
              alt="client"
            />
          </div>
          <div className="home_slide">
            <img
              className="home_image_slide"
              src="./client/client21.png"
              alt="client"
            />
          </div>
          <div className="home_slide">
            <img
              className="home_image_slide"
              src="./client/client22.png"
              alt="client"
            />
          </div>
          <div className="home_slide">
            <img
              className="home_image_slide"
              src="./client/client23.png"
              alt="client"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default index;
